import React from 'react'
import { graphql } from 'gatsby'

import MasonryGrid from '_core/components/organisms/masonry/MasonryGrid'
import MandateCard from '_core/components/molecules/cards/mandate/MandateCard'

import useDynamicData from '_core/helpers/useDynamicData'

import LandingPage, {
  LandingPageContextProps,
} from 'public/templates/layouts/LandingPage'

interface Props {
  data: Queries.MandateLandingQuery
  pageContext: LandingPageContextProps
}

const MandateLanding = ({ data, pageContext }: Props) => {
  const landing = data.datoCmsLandingPage
  const mandates = data.allDatoCmsPageMandate.edges
  // console.log(
  //   '🚀 ~ file: MandateLanding.tsx:21 ~ MandateLanding ~ mandates:',
  //   mandates
  // )

  const { isDynamic, filterBarProps, contentProps, paginationProps } =
    useDynamicData({
      ...pageContext,
      dynamicType: 'mandate',
      dynamicContentQuery: dynamicContentQuery,
      dataResultSelector: 'mandates',
      withTerms: true,
      withFilterBar: true,
    })

  return (
    <LandingPage
      metadata={{
        seoTags: landing.seoMetaTags,
        currentPage: pageContext.currentPage,
      }}
      heroProps={{
        mainHeading: landing.heroMainHeading,
        smallHeading: landing.heroSmallHeading,
        imagePortrait: landing.imagePortrait.gatsbyImageData,
        imageLandscape: landing.imageLandscape.gatsbyImageData,
      }}
      pageContext={pageContext}
      filterBarProps={{ ...pageContext, ...filterBarProps }}
      paginationProps={{ ...pageContext, ...paginationProps }}
      summaryItemName="mandates"
      pageNum={pageContext.currentPage}
    >
      {isDynamic == true &&
      contentProps &&
      contentProps.items &&
      contentProps.items.length > 0 ? (
        <div className="dynamic-content">
          <MasonryGrid>
            {contentProps.items.map((mandate: Queries.DatoCmsPageMandate) => {
              return (
                <MandateCard
                  key={`m-${mandate.node.originalId}`}
                  mandate={mandate.node}
                  isDynamic
                />
              )
            })}
          </MasonryGrid>
        </div>
      ) : (
        <MasonryGrid>
          {mandates.map((mandate: Queries.DatoCmsPageMandate) => {
            return (
              <MandateCard
                key={`m-${mandate.node.originalId}`}
                mandate={mandate.node}
              />
            )
          })}
        </MasonryGrid>
      )}
    </LandingPage>
  )
}

export const pageQuery = graphql`
  query MandateLanding($skip: Int!, $perPage: Int!) {
    datoCmsLandingPage(originalId: { eq: "1282080" }) {
      ...LandingPageTitleHero
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allDatoCmsPageMandate(
      sort: { fields: [active, meta___createdAt], order: [DESC, DESC] }
      limit: $perPage
      skip: $skip
    ) {
      edges {
        node {
          ...mandateCard
        }
      }
    }
  }
`

const dynamicContentQuery = `
  query($filter: MandateFilter, $pagination: PaginationOptions!) {
    mandates(filter: $filter, pagination: $pagination) {
      totalCount
      edges {
        node {
          _modelApiKey
          active
          id
          slug
          title
          minExperience
          maxExperience
          createdAt
          departments {
            name
            slug
            id
            _modelApiKey
            departmentColor {
              red
              green
              blue
            }             
          }
          regions {
            name
            slug
            id
            _modelApiKey
          }
          levels {
            name
            slug
            id
            _modelApiKey
          }
          locations {
            name
            slug
            id
            _modelApiKey
          }
          products {
            name
            slug
            id
            _modelApiKey
          }
          sectors {
            name
            slug
            id
            _modelApiKey
          }
          secondaryDepartments {
            name
            slug
            id
            _modelApiKey
          }
          secondaryRegions {
            name
            slug
            id
            _modelApiKey
          }
          responsibilities {
            name
            slug
            id
            _modelApiKey
          }
          languages {
            ... on LanguageRequirementRecord {
              id
              language {
                name
                slug
              }
              proficiency {
                name
              }
            }
          }
          bulletPoints {
            ... on BulletPointRecord {
              bulletPoint
            }
          }
        }
      }
    }
  }
`

export default MandateLanding
